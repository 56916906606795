import React from 'react';
import Script from '@gumgum/react-script-tag';

class Consent extends React.Component {
    _onCreate = () => {

      (function() {
      var gdprAppliesGlobally = false;
      function addFrame() {
          if (!window.frames['__cmpLocator']) {
          if (document.body) {
              var body = document.body,
                  iframe = document.createElement('iframe');
              iframe.style = 'display:none';
              iframe.name = '__cmpLocator';
              body.appendChild(iframe);
          } else {
              // In the case where this stub is located in the head,
              // this allows us to inject the iframe more quickly than
              // relying on DOMContentLoaded or other events.
              setTimeout(addFrame, 5);
          }
          }
      }
      addFrame();
      function cmpMsgHandler(event) {
          var msgIsString = typeof event.data === "string";
          var json;
          if(msgIsString) {
          json = event.data.indexOf("__cmpCall") != -1 ? JSON.parse(event.data) : {};
          } else {
          json = event.data;
          }
          if (json.__cmpCall) {
          var i = json.__cmpCall;
          window.__cmp(i.command, i.parameter, function(retValue, success) {
              var returnMsg = {"__cmpReturn": {
              "returnValue": retValue,
              "success": success,
              "callId": i.callId
              }};
              event.source.postMessage(msgIsString ?
              JSON.stringify(returnMsg) : returnMsg, '*');
          });
          }
      }

      window.__cmp = function (c) {
          var b = arguments;
          if (!b.length) {
          return window.__cmp.a;
          }
          else if (b[0] === 'ping') {
          b[2]({"gdprAppliesGlobally": gdprAppliesGlobally,
              "cmpLoaded": false}, true);
          } else if (c == '__cmp')
          return false;
          else {
          if (typeof window.__cmp.a === 'undefined') {
              window.__cmp.a = [];
          }
          window.__cmp.a.push([].slice.apply(b));
          }
      }
      window.__cmp.gdprAppliesGlobally = gdprAppliesGlobally;
      window.__cmp.msgHandler = cmpMsgHandler;
      if (window.addEventListener) {
          window.addEventListener('message', cmpMsgHandler, false);
      }
      else {
          window.attachEvent('onmessage', cmpMsgHandler);
      }
      })();
      window.__cmp('init', {
          'Language': 'pt',
          'Initial Screen Title Text': 'Damos valor à sua privacidade',
          'Initial Screen Reject Button Text': 'EU NÃO ACEITO',
          'Initial Screen Accept Button Text': 'EU ACEITO',
          'Initial Screen Purpose Link Text': 'Finalidades',
          'Purpose Screen Title Text': 'Damos valor à sua privacidade',
          'Purpose Screen Body Text': 'Escolha abaixo como deseja que seus dados pessoais sejam utilizados.',
          'Purpose Screen Vendor Link Text': 'Nossos Parceiros',
          'Purpose Screen Cancel Button Text': 'Cancelar',
          'Purpose Screen Save and Exit Button Text': 'Salvar e sair',
          'Vendor Screen Title Text': 'Damos valor à sua privacidade',
          'Vendor Screen Body Text': 'Defina as preferências de consentimento para cada um de nossos parceiros.',
          'Vendor Screen Accept All Button Text': 'ACEITAR TUDO',
          'Vendor Screen Reject All Button Text': 'REJEITAR TUDO',
          'Vendor Screen Purposes Link Text': 'Voltar para Finalidades',
          'Vendor Screen Cancel Button Text': 'Cancelar',
          'Vendor Screen Save and Exit Button Text': 'Salvar e sair',
          'Initial Screen Body Text': 'Dependemos da receita de publicidade para lhe fornecer conteúdos originais e de alta qualidade. Nós utilizamos os seus dados pessoais para personalizar anúncios e conteúdos com base nos seus interesses, e avaliar o desempenho desses anúncios. Clique abaixo para consentir o processamento dos seus dados.',
          'Publisher Name': 'Meu Crème Brûlée',
          'Publisher Logo': 'https://meucremebrulee.com.br/images/mcb.png',
          'Consent Scope': 'service',
          'No Option': false,
          'Publisher Vendor List URL': 'https://meucremebrulee.com.br/pubvendors.json',
      });
    };

    render() {
        return (
            <Script
                src="https://quantcast.mgr.consensu.org/cmp.js"
                type="text/javascript"
                onCreate={this._onCreate}
                async
            />
        );
    }
}

export default Consent;
