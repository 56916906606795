import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Menu, X } from 'react-feather'
import MediaQuery from 'react-responsive'
import Logo from './Logo'
import Social from './Social'
import Link from './Link'

import Grid from '@material-ui/core/Grid';

import './Nav.css'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
        <MediaQuery minDeviceWidth={750}>
          <div className="Nav--Container container">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={2}>
                <Link to="/" onClick={this.handleLinkClick}>
                  <Logo />
                </Link>
              </Grid>
              <Grid item xs={8}>
                <div className="Nav--Links">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/recipes/">Receitas</NavLink>
                  <NavLink to="/blog/">Artigos</NavLink>
                  <NavLink to="/contact/">Contato</NavLink>
                  <NavLink to="/privacy/">Política de Privacidade</NavLink>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Social />
              </Grid>
            </Grid>
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={750 - 1}>
          <div className="Nav--Container container">

            <Link to="/" onClick={this.handleLinkClick}>
              <Logo />
            </Link>
            <div className="Nav--Links">
              <NavLink to="/recipes/">Receitas</NavLink>
              <NavLink to="/blog/">Artigos</NavLink>
              <NavLink to="/contact/">Contato</NavLink>
              <NavLink to="/privacy/">Política de Privacidade</NavLink>
              <Social />
            </div>
            <button
              className="Button-blank Nav--MenuButton"
              onClick={this.handleMenuToggle}
            >
              {active ? <X /> : <Menu />}
            </button>
          </div>
        </MediaQuery>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
