import React from 'react';
import { serialize } from 'dom-form-serializer'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import FacebookIcon from '@material-ui/icons/Facebook';
import MediaQuery from 'react-responsive'

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const useStyles = makeStyles(theme => ({
  mobile: {
    '& > a > svg': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontSize: 25,
    },
  },
  desktop: {
    '& > a > svg': {
      margin: theme.spacing(1),
      fontSize: 20,
    },
    width: '100%',
  },
  iconHover: {
    '&:hover': {
      color: red[800],
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginBottom: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  midDivider: {
    width: '70%',
    margin: 10,
  },
  strongDivider: {
    width: '5%',
    margin: 3,
    backgroundColor: 'black'
  },
}));


export default function Social() {
  const classes = useStyles();

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    const data = serialize(form)

    addToMailchimp(data.emailAddress, {
      NAME: data.name,
      MESSAGE: data.message
    })
      .then(res => {
        if (res.result == 'success' || res.msg.includes('already subscribed')) {
          if (typeof window.fbq === 'function') {
            window.fbq('track', 'Lead');
          };
          
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        form.reset()
      })
      .catch(err => {
        console.error(err)
        form.reset()
      })
  }

  return (
    <React.Fragment>
      <MediaQuery minDeviceWidth={750}>
        <div className={classes.desktop}>
          <a href='https://www.instagram.com/meucremebrulee/' target="_blank">
            <InstagramIcon />
          </a>
          <a href='https://www.pinterest.com/meucremebrulee/' target="_blank">
            <PinterestIcon />
          </a>
          <a href='https://www.facebook.com/meucremebrulee/' target="_blank">
            <FacebookIcon />
          </a>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={750 - 1}>
        <Divider className={classes.midDivider} variant="middle" />
        <a className="NavLink">
          Assine nossa Newsletter
        </a>
        <Paper
          component="form"
          className={classes.root}
          onSubmit={handleSubmit}
        >
          <InputBase
            className={classes.input}
            placeholder="Seu email"
            type="email"
            name="emailAddress"
            required
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton className={classes.iconButton} type="submit">
            <PlayCircleOutlineIcon />
          </IconButton>
        </Paper>
        <a className="NavLink">
          Siga o MCB
        </a>
        <div className={classes.mobile}>
          <a href='https://www.instagram.com/meucremebrulee/' target="_blank">
            <InstagramIcon />
          </a>
          <a href='https://www.pinterest.com/meucremebrulee/' target="_blank">
            <PinterestIcon />
          </a>
          <a href='https://www.facebook.com/meucremebrulee/' target="_blank">
            <FacebookIcon />
          </a>
        </div>
      </MediaQuery>
    </React.Fragment>
  );
}
