import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {

  },
}));

const Link = props => {
  const { children, to, className, onClick } = props;

  const classes = useStyles();

  return (
    <a
      href={to}
      className={className}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

export default Link
